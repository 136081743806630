<template>
    <div class="case-container">
        <a-card title="项目案例管理">
            <div class="tool">
                <a-input-search style="width: 250px;" v-model="keyword" placeholder="请输入项目名称" enter-button allowClear @search="onSearch" />
                <div>
                    <a-range-picker :locale="locale" @change="pickerChange" style="margin-right: 10px"/>
                    <a-button type="primary" icon @click="add"><a-icon type="plus"></a-icon>新增</a-button>
                </div>
            </div>
            <div class="table">
                <a-table :rowKey="(item,i)=>item.id" :columns="columns" :data-source="tableData" :pagination="pagination" :loading="loading"></a-table>
            </div>
        </a-card>
        <a-modal :width="900" v-model="editWindowShow" :title="modalTitle" @ok="modalOk" cancelText="取消" okText="确定" :confirmLoading="confirmLoading">
            <a-form-model ref="form" :rules="formRules" :model="formData" :label-col="{span:5}" :wrapper-col="{span:12}">
                <a-form-model-item label="项目名称：" prop="name">
                    <a-input v-model="formData.name" placeholder="请输入项目名称"></a-input>
                </a-form-model-item>
                <a-form-model-item label="项目介绍：" prop="caseIntroduce">
                    <div class="editor" v-if="editWindowShow">
                        <Toolbar style="border-bottom: 1px solid #ccc;" :editor="editorRef" :defaultConfig="editorConfig.toolbarConfig" :mode="mode" />
                        <Editor  v-model="formData.introduce" :defaultConfig="editorConfig" :mode="mode" @onCreated="handleCreated" />
                    </div>
                </a-form-model-item>
                <a-form-model-item  label="应用软件：" prop="equipment">
                    <br/>
                   <div class="hardWare">
                       <div>
                           <span>硬件：</span>
                       </div>
                       <div v-for="(domain, index) in dynamicValidateFormHardWare.domains" :key="index">
                           <a-input  v-model="domain.value" placeholder="请输入硬件名称" style="width: 150px; margin-right: 8px"/>
                           <a-icon
                               v-if="dynamicValidateFormHardWare.domains.length > 1"
                               class="dynamic-delete-button"
                               type="minus-circle-o"
                               :disabled="dynamicValidateFormHardWare.domains.length === 1"
                               @click="removeDomainHardWare(domain)"
                           />
                       </div>
                       <a-button type="dashed" style="width: 120px" @click="addDomainHardWare"><a-icon type="plus" />添加</a-button>
                   </div>
                    <br/>
                    <div class="softWare">
                        <div>
                            <span>软件：</span>
                        </div>
                        <div v-for="(domain, index) in dynamicValidateFormSoftWare.domains" :key="index">
                            <a-input  v-model="domain.value" placeholder="请输入软件名称" style="width: 150px; margin-right: 8px"/>
                            <a-icon
                                v-if="dynamicValidateFormSoftWare.domains.length > 1"
                                class="dynamic-delete-button"
                                type="minus-circle-o"
                                :disabled="dynamicValidateFormSoftWare.domains.length === 1"
                                @click="removeDomainSoftWare(domain)"
                            />
                        </div>
                        <a-button type="dashed" style="width: 120px" @click="addDomainSoftWare"><a-icon type="plus" />添加</a-button>
                    </div>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>

</template>

<script>
import {h} from "vue";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import { toolbarConfig, MENU_CONF } from '@/utils/wangeditors.js'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import {projectCase, saveOrEditProjectCase,deleteProjectCase} from '@/api/apis'
import handleRequest from "@/utils/handleRequest";
export default {
    name: "caseOption",
    components: {
        Editor, Toolbar
    },
    data() {
        return {
            mode:'default',
            editorRef: null,
            editorConfig: {
                placeholder: '请输入内容...',
                //配置工具栏
                toolbarConfig: toolbarConfig,
                MENU_CONF: MENU_CONF,
            },
            locale,
            keyword:'',
            editWindowShow:false,
            confirmLoading:false,
            modalTitle:'新增',
            formData:{
                name:'',
                introduce:'',
                state:2,
                sort:'',
                equipment: {
                    hardware:[],
                    software:[],
                }
            },
            formRules: {
                name: [{required:true,message:'请输入名称',trigger:'blur'}],
                introduce: [{required:true,message:'请输入内容',trigger:'blur'}],
                equipment:[{validator:(rules,value,callback)=>{
                    if(this.formData.equipment.software.length != 0 && this.formData.equipment.hardware.length == 0){
                        console.log('当前有一个软件，但是没有硬件')
                        for(let i = 0;i<this.formData.equipment.software.length;i++){
                            let item = this.formData.equipment.software[i]
                            if(item.value){
                                return true
                                break;
                            }else{
                                callback('请填写当前软件名称')
                                break;
                            }
                        }
                    }else if(this.formData.equipment.hardware.length != 0 && this.formData.equipment.software.length == 0){
                        console.log('当前有一个硬件，但是没有软件')
                        for(let i = 0;i<this.formData.equipment.hardware.length;i++){
                            let item = this.formData.equipment.hardware[i]
                            if(item.value){
                                return true
                                break;
                            }else{
                                callback('请填写当前硬件名称')
                                break;
                            }
                        }
                    }else if(this.formData.equipment.software.length == 0 && this.formData.equipment.hardware.length == 0){
                        callback('请添加至少一个软硬件')
                    }else if(this.formData.equipment.software.length && this.formData.equipment.hardware.length){
                        for(let i = 0;i<this.formData.equipment.hardware.length;i++){
                            let item = this.formData.equipment.hardware[i]
                            if(item.value){
                                return true
                                break;
                            }else{
                                for(let i = 0;i<this.formData.equipment.software.length;i++){
                                    let item = this.formData.equipment.software[i]
                                    if(item.value){
                                        return true
                                        break;
                                    }else{
                                        callback('请填写当前软件或硬件名称')
                                        break;
                                    }
                                }
                                break;
                            }
                        }


                    }else{
                         return true
                    }
                    },trigger:'blur'}]
            },
            dynamicValidateFormHardWare: {
                domains: [],
            },
            dynamicValidateFormSoftWare: {
                domains: [],
            },
            columns:[
                {
                    title: '序号',
                    dataIndex: 'index',
                    key: 'index',
                    align: 'center',
                    customRender: (text,record,index) => `${index+1}`,
                },
                {
                    align: 'center',
                    dataIndex:'name',
                    key:'name',
                    title:'项目名称',
                },
                {
                    align: 'center',
                    dataIndex:'createTime',
                    key:'createTime',
                    title:'添加时间',
                },
                {
                    align: 'center',
                    dataIndex:'status',
                    key:'status',
                    title:'状态',
                    customRender:(text, record, index)=> {
                       return  h("a-switch",{
                           props:{
                               checked:record.state == 1 ? true:false
                           },
                            on:{
                                'change':()=>{
                                    this.switchChange(record)
                                }
                            }
                        })
                    }
                },
                {
                    align: 'center',
                    dataIndex:'action',
                    key:'action',
                    title:'操作',
                    width:200,
                    customRender:(text,record,index)=>{
                        return h("div",[
                            h("a-button", {
                                style:{
                                    marginRight:'10px'
                                },
                                props:{
                                    icon:'edit',
                                    type:'primary',
                                    size:'small'
                                },
                                on:{
                                    click:()=>{
                                        this.edit(record)
                                    }
                                }
                            }, '编辑'),
                            h("a-popconfirm",{
                                props:{
                                    title:'确定要删除吗？',
                                    okText:'确定',
                                    cancelText:'取消',
                                },
                                on:{
                                    'confirm':()=>{
                                        this.delete(record)
                                    }
                                }
                            },[
                                h("a-button",{
                                    props:{
                                        icon:'delete',
                                        type: 'danger',
                                        size:'small',
                                    }
                                },'删除')
                            ])
                        ])
                    }
                },
            ],
            tableData:[],
            loading:false,
            pagination:{
                pageSize:10,
                current:1,
                total:0,
                showTotal:(total)=>`共${total}条`,
                onChange:(page,pageSize)=>this.pageChange(page,pageSize)
            },
        }
    },
    mounted() {
        this.getTableData();
    },
    beforeDestroy() {
        // 组件销毁时，也及时销毁编辑器
        const editor = this.editorRef
        if (editor == null) return
        editor.destroy()
    },
    methods: {
        async switchChange(params){
            params.state = params.state == 1 ? false:true
            let switchedData = {...params}
            switchedData.hardware = JSON.stringify(switchedData.hardware)
            switchedData.software = JSON.stringify(switchedData.software)
            switchedData.state = switchedData.state == true ? 1:2
            const res = await saveOrEditProjectCase(switchedData)
            handleRequest(res,(res)=>{
                this.$message.success('切换成功')
            })
        },
        pageChange(page,pageSize){
            this.pagination.current = page
            this.getTableData()
        },
        async getTableData(startTime,endTime){
            this.loading = true
            let params = {
                pageSize: this.pagination.pageSize,
                page:this.pagination.current,
                name:this.keyword,
                startTime:startTime || '',
                endTime:endTime || ''
            }
            const res = await projectCase(params)
            const data = handleRequest(res)
            this.pagination.total = data.totalElements
            this.pagination.current = data.number+1
            this.tableData = data.content
            this.tableData.forEach(item=>{
                item.hardware = JSON.parse(item.hardware)
                item.software = JSON.parse(item.software)
            })
            this.loading = false
        },
        addDomainHardWare() {
            if(this.dynamicValidateFormHardWare.domains.length>=6){
                this.$message.error('至多6条');
                return
            }else{
                this.dynamicValidateFormHardWare.domains.push({
                    value: '',
                    // key: Date.now(),
                });
            }
        },
        addDomainSoftWare() {
            if(this.dynamicValidateFormSoftWare.domains.length>=6){
                this.$message.error('至多6条');
                return
            }else{
                this.dynamicValidateFormSoftWare.domains.push({
                    value: '',
                    // key: Date.now(),
                });
            }
        },
        removeDomainHardWare(item) {
            let index = this.dynamicValidateFormHardWare.domains.indexOf(item);
            if (index !== -1) {
                this.dynamicValidateFormHardWare.domains.splice(index, 1);
            }
        },
        removeDomainSoftWare(item) {
            let index = this.dynamicValidateFormSoftWare.domains.indexOf(item);
            if (index !== -1) {
                this.dynamicValidateFormSoftWare.domains.splice(index, 1);
            }
        },
        handleCreated(editor) {
            this.editorRef = editor // 记录 editor 实例
        },
        pickerChange(date,dateString){
            console.log(dateString)
            this.pagination.current = 1
            this.getTableData(dateString[0],dateString[1])
        },
        onSearch(){
            this.getTableData();
        },
        modalOk(){
            this.confirmLoading = true
            console.log('编辑窗口确定')
            this.formData.equipment.hardware = this.dynamicValidateFormHardWare.domains
            this.formData.equipment.software = this.dynamicValidateFormSoftWare.domains
            this.$refs.form.validate(async valid => {
                if (valid) {
                    let params = {
                        id:this.formData.id || void 0,
                        name:this.formData.name,
                        introduce:this.formData.introduce,
                        sort:this.tableData.length+1,
                        state:2,
                        hardware:JSON.stringify(this.formData.equipment.hardware.map(item=>item.value)),
                        software:JSON.stringify(this.formData.equipment.software.map(item=>item.value)),
                    }
                    const res = await saveOrEditProjectCase(params)
                    handleRequest(res,(res)=>{
                        this.$message.success('操作成功')
                        this.confirmLoading = false
                        this.editWindowShow = false
                        this.getTableData();
                    })
                } else {
                    this.confirmLoading = false
                    console.log('验证错误');
                    console.log(this.formData)
                    return false;
                }
            });
        },
        add(){
            this.editWindowShow = true
            this.modalTitle = '新增'
            this.formData = {
                name:'',
                introduce:'',
                state:2,
                sort:'',
                equipment: {
                    hardware:[],
                    software:[],
                }
            }
            this.dynamicValidateFormHardWare.domains = []
            this.dynamicValidateFormSoftWare.domains = []
        },
        edit(params){
            this.editWindowShow = true
            this.modalTitle = '编辑'
            this.formData = params
            this.formData.equipment = {
                hardware:this.formData.hardware.map(item=>{return {value:item}}),
                software:this.formData.software.map(item=>{return {value:item}})
            }
            this.dynamicValidateFormHardWare.domains = this.formData.equipment.hardware
            this.dynamicValidateFormSoftWare.domains = this.formData.equipment.software
        },
        async delete(params){
            console.log(params)
            const res = await deleteProjectCase({id:params.id})
            handleRequest(res,()=>{
                this.$message.success('删除成功')
                this.getTableData();
            })
        }
    },
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped lang="scss">
::v-deep .w-e-scroll{
    min-height: 300px;
}
.editor{
    z-index: 999999;
    width: 600px;
    min-height: 300px;
    border: 1px solid #8d8d8d;
}
.hardWare{

}
.case-container{
    padding: 20px;
    .tool{
        display: flex !important;
        justify-content: space-between;
        align-items: center;
    }
    .table{
        margin-top: 30px;
    }
}
</style>
